(()=>{
  let win           = window;
  let $win          = $(window);
  let $body         = $('body');
  let eventListener = marugoto.eventListener;
  let state         =  marugoto.state;
  // ===========================================================================
  // メニュー
  // ===========================================================================
  {
    let $wrap    = $('.p__content');
    let scale    = 0.9;
    let $btn     = $('.menu li', $wrap);
    let duration = 200;
    $btn.on({
      'mouseover' : function(){
        if(state.displaySize === 'small') return false;
        let $this = $(this);
        $this.velocity('stop').velocity({
            'scale' : 1.0
          },{
            'duration' : duration
        });
      },
      'mouseout' : function(){
        if(state.displaySize === 'small') return false;
        let $this = $(this);
        $this.velocity('stop').velocity({
            'scale' : scale
          },{
            'duration' : duration
        });
      }
    });
    // -------------------------------------------------------------------------
    // サイズブレイク時
    // -------------------------------------------------------------------------
    eventListener.break.menuBtn = function(e, size){
      if(size === 'large'){
        $btn.velocity('stop').velocity({scale : scale},{duration : 0});
      }else{
        $btn.velocity('stop').velocity({scale : 1},{duration : 0});
      }
    }
  }
  // ===========================================================================
  // コンテンツフィット
  // ===========================================================================
  {
    let $header = $('.f__header-content');
    let $parent = $('.p__content');
    let $target = $('> div', $parent);
    let $win    = $(window);
    let minW    = 600;
    let winW    = 0;
    let winH    = 0;
    let cntH    = 0;
    // // let w       = 1133;
    // // let h       = 930;
    let w       = 1580;
    let h       = 960;
    // -------------------------------------------------------------------------
    // フィットスタイルのリセット
    // -------------------------------------------------------------------------
    let fit = ()=>{
      let scale = cntH / h;
      if(w * scale < minW){
        scale = winW / w;
      }
      if(scale > 1) scale = 1;
      let fixH  = h * scale;
      $parent.css({
        'width'  : winW,
        'height' : fixH
      });
      $target.css({
        'transform' : `scale(${scale})`
      });
    }
    // -------------------------------------------------------------------------
    // フィットスタイルのリセット
    // -------------------------------------------------------------------------
    let reset = ()=>{
      $parent.css('width', '');
      $parent.css('height', '');
      $target.css('transform', '');
    }
    // -------------------------------------------------------------------------
    // リサイズ時
    // -------------------------------------------------------------------------
    eventListener.resize.sizeFit = ()=>{
      winW = $win.innerWidth();
      winH = $win.innerHeight();
      cntH = winH - $header.innerHeight();
      if(winW <= window.marugoto.breakPoint){
        reset();
      }else{
        fit();
      }
      // console.log(winW);
      // if()
      // console.log(window.marugoto.breakPoint);
      // console.log($win.innerWidth());
      // console.log(state.displaySize);
    }
  }
  // ===========================================================================
  // OGV
  // ===========================================================================
  {
    $root       = $('.p__content');
    $leftVideo  = $('.left li.video',  $root);
    $rightVideo = $('.right li.video', $root);
    let size;
    let leftPlayer;
    let rightPlayer;
    // -------------------------------------------------------------------------
    // 動画セットアップ
    // -------------------------------------------------------------------------
    let setup = (size)=>{
      leftPlayer = new OGVPlayer();
      leftPlayer.muted  = true;
      leftPlayer.src    = $leftVideo.data(`${size}-video`);
      leftPlayer.width  = $leftVideo.width();
      leftPlayer.height = $leftVideo.height();
      $leftVideo.append(leftPlayer);
      leftPlayer.play();
      if(size === 'large'){
        leftPlayer.poster = `${marugoto.srcPath.img}common/page/toppage/left_poster--large.jpg`;
      }else{
        leftPlayer.poster = `${marugoto.srcPath.img}common/page/toppage/left_poster--small.jpg`;
      }
      leftPlayer.addEventListener('ended', ()=>{
        leftPlayer.play();
      });
      rightPlayer = new OGVPlayer();
      rightPlayer.muted  = true;
      rightPlayer.src    = $rightVideo.data(`${size}-video`);
      rightPlayer.width  = $rightVideo.width();
      rightPlayer.height = $rightVideo.height();
      if(size === 'large'){
        rightPlayer.poster = `${marugoto.srcPath.img}common/page/toppage/right_poster--large.jpg`;
      }else{
        rightPlayer.poster = `${marugoto.srcPath.img}common/page/toppage/right_poster--small.jpg`;
      }
      $rightVideo.append(rightPlayer);
      rightPlayer.play();
      rightPlayer.addEventListener('ended', ()=>{
        rightPlayer.play();
      });
    }
    // -------------------------------------------------------------------------
    // ウィンドウフォーカス/ブラー
    // -------------------------------------------------------------------------
    eventListener.toggleFocus.ogv = (v)=>{
      if(v === 'blur'){
        if(leftPlayer)  leftPlayer.pause();
        if(rightPlayer) rightPlayer.pause();
      }else{
        if(leftPlayer)  leftPlayer.play();
        if(rightPlayer) rightPlayer.play();
      }
    }
    // -------------------------------------------------------------------------
    // リセット
    // -------------------------------------------------------------------------
    let reset = ()=>{
      if(leftPlayer){
        $leftVideo.empty();
        leftPlayer = undefined;
      }
      if(rightPlayer){
        $rightVideo.empty();
        rightPlayer = undefined;
      }
    }
    // -------------------------------------------------------------------------
    // ブレイク時の処理
    // -------------------------------------------------------------------------
    eventListener.break.bgMovie = (e, _size)=>{
      size = _size;
      reset();
      setup(size);
    }
  }
})();
